.mob-menu {
  display: flex;
  justify-content: space-around;

}
.moblink {
  color: black;
  font-weight: 100;
  font-family: sans-serif;
  font-size: 8px;
  margin-top: 2.3rem;
  letter-spacing: 2px;

}
.moblink:hover {
  text-decoration: none;
  color:black;
}
.moblink > img {
  margin-top: -2.1rem;
  width: 80px;

}
