footer {

  height: 40px;
}
.footdiv {
  display:flex;
  justify-content: space-between;
}
.footdiv > p {
   font-weight: 100;
   font-size: 13px;
   font-family: sans-serif;

   opacity: 60%;
}
.eight {
  color: #7A1D00;
  font-weight: bold;


}
.str8jacket-footer {
 margin-left: 20px;
  margin-top: 11.7px;



}


.jlanier {
  color:#7A1D00;
  letter-spacing: 3px;
  margin-top: 13.2px;
  font-size: 8px;
  font-weight: 100;
  margin-right: 20px;
}
.footdiv > a:hover {
  text-decoration: none;
}
