body{
  height: 100%;
  width: 100%;
}



.photo-div {

  background-image:  url('./../../images/homepage.jpg') ;
  background-size: cover;
  background-repeat: no-repeat;
  background-position:center;



  height: 40em;
  animation: slide 40s infinite;
}

@keyframes slide {
  0%{
    background-image: url('./../../images/homepage.jpg');
  }
  10%{
    background-image: url('./../../images/homepage2.jpg');

  }
  20%{
    background-image: url('./../../images/homepage3.jpg');
  }
  30%{
    background-image: url('./../../images/homepage4.jpg');
  }
  40%{
    background-image: url('./../../images/homepage5.jpg');
  }
  50%{
    background-image: url('./../../images/homepage6.jpg');
  }
  60%{
    background-image: url('./../../images/homepage7.jpg');
  }
  70%{
    background-image: url('./../../images/homepage8.jpg');
  }
  80%{
    background-image: url('./../../images/homepage9.jpg');
  }
  90%{
    background-image: url('./../../images/homepage10.jpg');
  }

}
div.preload-images {
    background: url("./../../images/homepage.jpg") no-repeat -9999px -9999px;
    background: url("./../../images/homepage2.jpg") no-repeat -9999px -9999px,
                 url("./../../images/homepage3.jpg") no-repeat -9999px -9999px,
                url("./../../images/homepage4.jpg") no-repeat -9999px -9999px,
                url("./../../images/homepage5.jpg") no-repeat -9999px -9999px,
                url("./../../images/homepage6.jpg") no-repeat -9999px -9999px,
                url("./../../images/homepage7.jpg") no-repeat -9999px -9999px,
                url("./../../images/homepage8.jpg") no-repeat -9999px -9999px,
                url("./../../images/homepage9.jpg") no-repeat -9999px -9999px,
                url("./../../images/homepage10.jpg") no-repeat -9999px -9999px;
}
.mobile-photo-div {

  background-image:  url('./../../images/homepage.jpg') ;
  background-size: cover;
  background-repeat: no-repeat;
  background-position:center;



  height: 15em;
  animation: slide 40s infinite;
}

@keyframes slide {
  0%{
    background-image: url('./../../images/homepage.jpg');
  }
  10%{
    background-image: url('./../../images/homepage2.jpg');

  }
  20%{
    background-image: url('./../../images/homepage3.jpg');
  }
  30%{
    background-image: url('./../../images/homepage4.jpg');
  }
  40%{
    background-image: url('./../../images/homepage5.jpg');
  }
  50%{
    background-image: url('./../../images/homepage6.jpg');
  }
  60%{
    background-image: url('./../../images/homepage7.jpg');
  }
  70%{
    background-image: url('./../../images/homepage8.jpg');
  }
  80%{
    background-image: url('./../../images/homepage9.jpg');
  }
  90%{
    background-image: url('./../../images/homepage10.jpg');
  }

}
div.mobile-preload-images {
    background: url("./../../images/homepage.jpg") no-repeat -9999px -9999px;
    background: url("./../../images/homepage2.jpg") no-repeat -9999px -9999px,
                 url("./../../images/homepage3.jpg") no-repeat -9999px -9999px,
                url("./../../images/homepage4.jpg") no-repeat -9999px -9999px,
                url("./../../images/homepage5.jpg") no-repeat -9999px -9999px,
                url("./../../images/homepage6.jpg") no-repeat -9999px -9999px,
                url("./../../images/homepage7.jpg") no-repeat -9999px -9999px,
                url("./../../images/homepage8.jpg") no-repeat -9999px -9999px,
                url("./../../images/homepage9.jpg") no-repeat -9999px -9999px,
                url("./../../images/homepage10.jpg") no-repeat -9999px -9999px;
}

.josh-div {
  margin-top: 3rem;
  display:flex;
  flex-direction: column;
  text-align: center;
}
.josh-name {
  font-weight: 100;
  font-family: sans-serif;
  font-size: 13px;
  letter-spacing: 7px;
  opacity: 60%;
}
.josh-p {
  margin-top: 2%;
  padding-left: 10%;
  padding-right: 10%;
  font-family: sans-serif;
  font-weight: 100;
  font-size: 14px;
  letter-spacing: 1px;

}
.josh-div > a {
  font-weight: lighter;
  font-family: sans-serif;
  letter-spacing: 1px;
  font-size: 12px;



}
.josh-div > a:hover {
  text-decoration: none;

}
.photo-strip {
  display:flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 5%;
  width: 100%;
  height: auto;
  margin-bottom: 5%;

}
.left-box {
  margin-right: 20px;
  width: 351px;
  height: 485px;
  background-image: url('./../../images/allie.jpg');
  background-size: cover;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.left-box-mobile {
  margin-right: 10px;
  background-image: url('./../../images/allie.jpg');
  width: 140px;
  height: 200px;
  background-size: cover;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.right-box {
  margin-left: 20px;
  width: 351px;
  height: 485px;
  background-image:url('./../../images/clock.jpg');
  background-size: cover;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.right-box-mobile {
  margin-left: 10px;
  width: 140px;
  height: 200px;
  background-image:url('./../../images/clock.jpg');
  background-size: cover;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.left-photo-box {
  margin-top: 2rem;
  margin-left: 2rem;
  width: 18rem;
  height: 26rem;
  border:solid;
  border-width: thin;
  border-color: white;
}
.left-photo-box-mobile {
  border: solid;
  border-color: white;
  border-width: thin;
  width: 110px;
  height: 170px;
  margin-top: .9rem;
  margin-left: .9rem;
}
.right-photo-box-mobile {
  border: solid;
  border-color: black;
  border-width: thin;
  width: 110px;
  height: 170px;
  margin-top: .9rem;
  margin-left: .9rem;
}
.right-photo-box {
  margin-top: 2rem;
  margin-left: 2rem;
  width: 18rem;
  height: 26rem;
  border:solid;
  border-width: thin;
  border-color: black;
}
.photo-text {
  font-weight: 100;
  font-family: sans-serif;
  color: white;
  font-size: 34px;
  text-align: center;
  margin-top: 60%;
  transition:1s;
}
.photo-text-mobile {
  font-weight: 100;
  font-family: sans-serif;
  color: #7A1D00;
  font-size: 12px;
  text-align: center;
  margin-top: 60%;
  transition:1s;
}
.video-text-mobile {
  font-weight: 100;
  font-family: sans-serif;
  color: #7A1D00;
  font-size: 12px;
  text-align: center;
  margin-top: 60%;
  transition: 1s;
}
.photo-text:hover {
  transition: 1s;
  color: #7A1D00;
}
.video-text {
  font-weight: 100;
  font-family: sans-serif;
  color: black;
  font-size: 34px;
  text-align: center;
  margin-top: 60%;
  transition: 1s;
}
.video-text:hover {
  transition: 1s;
  color: #7A1D00;
}

.vlink:hover {
  text-decoration: none;

}
.NavLink:hover {
  text-decoration: none;

}
.seemore {
  text-align: center;
  color: white;
  font-family: sans-serif;
  font-weight: 500;
  letter-spacing: 2px;
  font-size: 14px;
  margin-top: 2rem;
  transition:1s;
}
.seemore-mobile {
  text-align: center;
  color: #7A1D00;
  font-family: sans-serif;
  font-weight: 500;
  letter-spacing: 2px;
  font-size: 12px;
  margin-top: 2rem;
  transition:1s;
}
.seemore:hover {
  transition: 1s;
  color: #7A1D00;
}
.seemore-two {
  text-align: center;
  color: black;
  font-family: sans-serif;
  font-weight: 500;
  letter-spacing: 2px;
  font-size: 14px;
  margin-top: 2rem;
  transition: 1s;
}
.seemore-two-mobile {
  text-align: center;
  color: #7A1D00;
  font-family: sans-serif;
  font-weight: 500;
  letter-spacing: 2px;
  font-size: 12px;
  margin-top: 2rem;
  transition: 1s;
}
.seemore-two:hover {
  transition: 1s;
  color: #7A1D00;
}
.socialmedia {
  height: 100px;
  display: flex;
  justify-content: center;
}

.fa-instagram {
  margin-right: 20px;
}
.fa-youtube {
  margin-left: 20px;
}
.fa-pinterest-p {
  margin-left: 40px;
}
.fa-facebook-f {
  margin-right: 40px;
}
.socialmedia > i {
  margin-top: 5rem;
}
.socialmedia > a {
  margin-top: 4rem;
  color:black;
}
.socialmedia > a:hover {
  transition: 1s;
  color: #7A1D00;
}
.multiphoto {
  height: 270px;
}
.multiphoto > img {
  width: 20%;
  height: auto;
  margin-top:6.3rem;
}
