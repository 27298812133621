.left-photo {
  width: 400px;
  margin-left: 40px;

}
.left-photo > img {
  width: 100%;
  height: auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}
.top-div {
  display: flex;
  justify-content: center;
  height: 20rem;
}
.top-div > a {
 color: black;
 font-size: 13px;
 margin-top:17%;

}
.top-div > a:hover {
  color: black;

}
.about-div {
  display: flex;
  justify-content: space-around;

}
.bio-box {
  width: 400px;
  margin-right: 50px;

}
.short-a {
  font-size: 12px;
  color: #7A1D00;
  margin-left: 20px;
  letter-spacing: 2px;
}
.short-a:hover {
  text-decoration: none;
  color: #7A1D00;
}
.theshort {
  margin-top: 15px;
  font-weight: 200;

}
.short-bio {
font-weight: 100;
letter-spacing: 1px;
margin-top: 30px;
transition: 1s;
}


.long-p-div {

  margin-top: 7rem;
}




.longbio-div {
width: 60.7rem;
margin-left: 9.4rem;


}
.the-long {
  font-weight: 200;
}
.long-p {
  font-weight: 100;
  letter-spacing: 1px;
  margin-top: 2rem;
}
.camera > img {
  width: 80px;
  height: auto;

  margin-top: 30px;
}
.camera {
  display: flex;
  justify-content: center;
  text-align: center;

}
.mobile-left-photo > img {
  width: 90%;
  height: auto;
  margin-left: 24px;

}
/* .mobile-underpic-div {
  border-bottom: solid;
  border-bottom-width: thin;
  margin-left: 15%;
  margin-right:15%;
  border-bottom-color: #D5D5D5;
  margin-top: -10%;
  margin-bottom: 10%;
} */

.history {
  text-align: center;
  font-weight: 300;

}
.mobile-p-div {
  width:90%;
  margin-left: 5%;
  border: solid black;
  border-width: thin;

}
.mobile-p {
  color:black;
  margin-left: 6%;
  margin-top: 5%;
  font-size: 12px;
  letter-spacing: 1px;
  width: 90%;
  font-weight: 400;
}
.mobile-left-photo {
  margin-right: 3%;
}
#test  {

    animation: fadein 3s;
    -moz-animation: fadein 3s; /* Firefox */
    -webkit-animation: fadein 3s; /* Safari and Chrome */
    -o-animation: fadein 3s; /* Opera */
}
@keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:100%;
    }
}
@-moz-keyframes fadein { /* Firefox */
    from {
        opacity:0;
    }
    to {
        opacity:100%;
    }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
    from {
        opacity:0;
    }
    to {
        opacity:100%;
    }
}
@-o-keyframes fadein { /* Opera */
    from {
        opacity:0;
    }
    to {
        opacity: 100%;
    }
}
