.gal-top-div {
  height: 17rem;

}

.mobile-top-div {
  text-align: center;
  height:6rem;
}
.mobile-under-menu-div {
  border-bottom: solid;
  border-bottom-width:thin;
  margin-left: 10%;
  margin-right: 10%;
  border-bottom-color: #D5D5D5;
}
.mobile-top-div > p {
  margin-top: 10%;
  font-size: 30px;
  font-weight: 200;
  letter-spacing: 7px;
  font-family: inherit;

}

.borderline-top {
  border-bottom: solid;
  border-bottom-width:thin;
  margin-left: 5%;
  margin-right: 5%;
  border-bottom-color: #D5D5D5;
  margin-top: -5%;
}
.undergalborder {
  border-bottom: solid;
  border-bottom-width:thin;
  margin-left: 5%;
  margin-right: 5%;
  border-bottom-color: #D5D5D5;
  margin-top: 5%;
  margin-bottom: 5%;
}

.gallery-div > p {
  margin-top: 5%;
  text-align: center;
  font-weight: 200;
  font-size: 35px;
  letter-spacing: 35px;
}
.photo-gal-div {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 530px;
  height: 320px;
  margin-left: 3rem;
  background-image: url("./../../images/photodiv.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.mobile-out-div {
  display: flex;
  justify-content: center;
  margin-bottom: 4%;
}
.mobile-out-pic-div {
  display: flex;
  justify-content: center;
}
.mobile-photo-gal-div {

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 277px;
  height: 150px;
  margin-bottom: 5%;
  background-image: url("./../../images/photodiv.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.mobile-fontfam {
  height: 4rem;
  border-bottom: solid;
  border-bottom-width:thin;
  margin-left: 5%;
  margin-right: 5%;
  border-bottom-color: #D5D5D5;
  margin-top: 1%;
  margin-bottom: 7%;
}
.mobile-undergal-div {
  margin-bottom: 14%;
  margin-top: -3%;
  border-bottom: solid;
  border-bottom-width:thin;
  margin-left: 10%;
  margin-right: 10%;
  border-bottom-color: #D5D5D5;
}
.mobile-fontfam > i {
  margin-top: 3rem;
}
.video-div {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-right: 3rem;
  width: 530px;
  height: 320px;
  background-image: url("./../../images/videodiv.png");
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
}
.mobile-gal-vid-div {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  margin-top: 3rem;
  width: 277px;
  height: 150px;
  background-image: url("./../../images/videodiv.png");
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
}

.galdiv {
  display: flex;

  justify-content: space-between;
}

.pic-gal-div {
  color: white;
  font-weight: 200;
  font-size: 50px;
  letter-spacing: 8px;
  text-align: center;
  padding-top: 7.6rem;

}
.mobile-pic-gal-div {
  color: white;
  font-weight: 200;
  font-size: 15px;
  letter-spacing: 8px;
  text-align: center;
  padding-top: 4.2rem;

}

.video-div {
  color: white;
  font-weight: 200;
  font-size: 50px;
  letter-spacing: 8px;
  text-align: center;
  padding-top: 7.6rem;
}
.mobile-video-div {
  color: white;
  font-weight: 200;
  font-size: 15px;
  letter-spacing: 8px;
  text-align: center;
  padding-top: 4.2rem;
}
.galdiv-atag:hover {
  text-decoration: none;
  color: white;
}
.mobile-galdiv-atag{
  display: flex;
  justify-content: center;
}
.mobile-galdiv-atag:hover {
  text-decoration: none;
}
.blankdiv {
  display: flex;
  justify-content: center;
  margin-top: 5%;

}
.mbottom-div {
  border-bottom: solid;
  border-bottom-width:thin;
  margin-left: 10%;
  margin-right: 10%;
  border-bottom-color: #D5D5D5;
  margin-top: 15%;
  margin-bottom: 2%;

}
.munderbottom-div {
  border-bottom: solid;
  border-bottom-width:thin;
  margin-left: 10%;
  margin-right: 10%;
  border-bottom-color: #D5D5D5;
  margin-top: 15%;
  margin-bottom: 6%;
}
