
a.nav-link {
  color:black!important;
  font-weight: 200;
  text-decoration: line-through;
  font-family: sans-serif;
  font-size: 13px;
  letter-spacing: 2px;
  text-align: center;



}
a.nav-link:hover {
  color:red!important;


}
.nav-home {
  margin-left: 9.1rem;
}
.nav-gallery {
  margin-left: 7rem;
}
.nav-about {
  margin-left: 7rem;
}
.nav-contact {
  margin-left: 7rem;
}

.logo > a {
  margin-left: 7rem;
}



header {
  position: fixed;
}
