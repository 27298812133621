.firstrow {
  /* height: 18rem; */
}
.firstrow > img {
  width: 32%;
  height: auto;
  padding: 1%;
  margin-left: 1%;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}
.secondrow {
  /* height: 18rem; */
}
.secondrow >img {
  width: 32%;
  height: auto;
  padding: 1%;
  margin-left: 1%;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}
.thirdrow > img {
  width: 32%;
  height: auto;
  padding: 1%;
  margin-left: 1%;
}
.forthrow >img {
  width: 98%;
  height: auto;
  padding: 1%;
  margin-left: 1%;
}
.fifthrow > img {
  width: 18.8%;
  height: auto;
  padding: 1%;
  margin-left: 1%;
}
.sixthrow > img {
  width: 32%;
  height: auto;
  padding: 1%;
  margin-left: 1%;
}
.seventhrow >img {
  width: 98%;
  height: auto;
  padding: 1%;
  margin-left: 1%;
}
.eighthrow > img {
  width: 32%;
  height: auto;
  padding: 1%;
  margin-left: 1%;
}
.ninthrow > img {
  width: 98%;
  height: auto;
  padding: 1%;
  margin-left: 1%;
}
.tenthrow > img {
  width: 32%;
  height: auto;
  padding: 1%;
  margin-left: 1%;
}
.eleventhrow > img {
  width: 32%;
  height: auto;
  padding: 1%;
  margin-left: 1%;
}
.twelthrow > img {
  width: 18.8%;
  height: auto;
  padding: 1%;
  margin-left: 1%;
}
.thirteenrow > img {
  width: 98%;
  height: auto;
  padding: 1%;
  margin-left: 1%;
}
.fourteenrow > img {
  width: 32%;
  height: auto;
  padding: 1%;
  margin-left: 1%;
}
.fifthteenrow > img {
  width: 32%;
  height: auto;
  padding: 1%;
  margin-left: 1%;
}
.sixthteenrow > img {
  width: 32%;
  height: auto;
  padding: 1%;
  margin-left: 1%
}
.seventhteenrow > img {
  width: 32%;
  height: auto;
  padding: 1%;
  margin-left: 1%
}
.eightteenrow > img {
  width: 18.8%;
  height: auto;
  padding: 1%;
  margin-left: 1%;
}
.nineteenrow > img {
  width: 32%;
  height: auto;
  padding: 1%;
  margin-left: 1%
}
.twentyrow > img {
  width: 32%;
  height: auto;
  padding: 1%;
  margin-left: 1%
}
.twentyonerow > img {
  width: 32%;
  height: auto;
  padding: 1%;
  margin-left: 1%
}
.twentytworow > img {
  width: 32%;
  height: auto;
  padding: 1%;
  margin-left: 1%
}
